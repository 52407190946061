import React, { Component } from "react"
import {
  registrationSound,
  errorSound,
  clearSound,
} from "../../helpers/sound.js"
import { verifyRegister } from "../../services/register.js"
import PointState from "../../states/point-state.js"
import {
  inputDefault,
  inputError,
  inputSuccess,
  inputLoading,
  labelSuccess,
  labelDefault,
  labelError,
  labelEmpty,
  labelLoading,
  submitOff,
  submitOn,
} from "./qrform.refs.js"

//Stupid solution, but ok.

class QrForm extends Component {
  constructor() {
    super()
    this.state = { value: "" }

    this.refInput = React.createRef()
    this.refLabel = React.createRef()
    this.refSubmit = React.createRef()
    this.refCancel = React.createRef()

    this.changeHandle = this.changeHandle.bind(this)
    this.keyPressHandle = this.keyPressHandle.bind(this)
    this.addRegistration = this.addRegistration.bind(this)
    this.checkingRegistration = this.checkingRegistration.bind(this)
    this.clearRegistration = this.clearRegistration.bind(this)
  }

  defaultView() {
    labelDefault(this.refLabel)
    inputDefault(this.refInput)
    submitOn(this.refSubmit)
    submitOff(this.refCancel)
  }

  successView(name) {
    inputSuccess(this.refInput)
    labelSuccess(this.refLabel, name)
    submitOff(this.refSubmit)
    submitOn(this.refCancel)
  }

  deniedView() {
    inputError(this.refInput)
    labelError(this.refLabel)
  }

  clearRegistration() {
    clearSound()
    PointState.set("registration", undefined)
    PointState.set("name", undefined)
  }

  getRegistration() {
    PointState.subscribe(data => {
      if (data.registration) {
        this.successView(data.name)
      } else {
        this.defaultView()
      }

      this.setState({
        value: data.registration || "",
        registration: true,
      })
    })
  }

  checkingRegistration(data) {
    if (data.validado === 1) {
      PointState.set("registration", this.state.value)
      PointState.set("name", data.nome)
      this.successView(data.nome)
      registrationSound()
    } else {
      this.deniedView()
      errorSound()
    }
  }

  addRegistration() {
    const { value } = this.state

    if (value) {
      labelLoading(this.refLabel)
      inputLoading(this.refInput)

      verifyRegister({
        register: value,
        id: localStorage.getItem("id"),
      }).then(this.checkingRegistration)
    } else {
      labelEmpty(this.refLabel)
    }
  }

  keyPressHandle(event) {
    const { value } = this.state

    if (event.key === "Enter" && value) {
      this.addRegistration()
      event.target.blur()
    }
  }

  changeHandle(event) {
    this.defaultView()

    this.setState({
      value: event.target.value,
      registration: false,
    })
  }

  componentDidMount() {
    submitOff(this.refCancel)
    this.getRegistration()
  }

  render() {
    return (
      <section className="qrform">
        <p ref={this.refLabel} className="qrform__label">
          Caso não possua o QR-CODE digite a sua matricula.
        </p>

        <input
          type="text"
          ref={this.refInput}
          value={this.state.value}
          className="form-control qrform__control"
          placeholder="Digite a sua matricula"
          onKeyPress={this.keyPressHandle}
          onChange={this.changeHandle}
        />

        <button
          ref={this.refSubmit}
          className="btn qrform__submit"
          onClick={this.addRegistration}
        >
          Registrar Matricula
        </button>

        <button
          ref={this.refCancel}
          onClick={this.clearRegistration}
          className="btn"
        >
          Limpar matricula
        </button>
      </section>
    )
  }
}

export default QrForm
