import axios from "axios"
import SERVERS from "../helpers/servers.js"

const doLogin = ({ email, password, error, success }) => {
  const ENDPOINT = `${SERVERS.PRODUCTION}/login/loginapp`

  axios
    .post(ENDPOINT, {
      login: email,
      senha: password,
    })
    .then(res => {
      const { id, permissao, nome_fantasia } = res.data

      if (permissao === 1 || permissao === 2) {
        localStorage.setItem("email", email)
        localStorage.setItem("id", id)
        localStorage.setItem("permission", permissao)
        localStorage.setItem("name", nome_fantasia || "Indefinido")
        localStorage.setItem("is-logged", "allow")
        success()
      } else {
        error()
      }
    })
    .catch(console.log)
}

const doLogout = ({ email, senha }) => {
  const ENDPOINT = `${SERVERS.PRODUCTION}/login/loginapp`

  return axios.post(ENDPOINT, {
    login: email,
    senha: senha,
  })
}

export { doLogin, doLogout }
