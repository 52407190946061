import React, { Component } from "react"
import Cam from "../../components/Cam/Cam.js"
import Sidebar from "../../components/Sidebar/Sidebar.js"
import Confirm from "../../components/Confirm/Confirm.js"
import PointState from "../../states/point-state.js"

class Ponto extends Component {
  constructor() {
    super()

    this.state = {
      registration: undefined,
      photo: undefined,
      name: undefined,
    }
  }

  componentDidMount() {
    PointState.subscribe(data => {
      this.setState({
        registration: data.registration,
        photo: data.photo,
        name: data.name,
      })
    })
  }

  render() {
    return (
      <section className="ponto">
        <div className="ponto__wrapper">
          <Cam />
          <Sidebar />
          {this.state.photo ? (
            <Confirm
              registration={this.state.registration}
              photo={this.state.photo}
              name={this.state.name}
            />
          ) : (
            ""
          )}
        </div>
      </section>
    )
  }
}

export default Ponto
