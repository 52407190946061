import React, { Component } from "react"
import { _if_else } from "../../helpers/render"

class Finish extends Component {
  constructor() {
    super()

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(event) {
    event.stopPropagation()
  }

  render() {
    const { message, error } = this.props.status

    const iconClasses = error
      ? "material-icons finish__icon finish__icon--error"
      : "material-icons finish__icon finish__icon--success"

    return (
      <section onClick={this.handleClick} className="finish">
        <i className={iconClasses}>
          {_if_else(error, "error_outline", "done")}
        </i>

        <h1 className="finish__title">{_if_else(error, "Falha", "Sucesso")}</h1>

        <span className="finish__message"> {message} </span>

        <button onClick={this.props.action} className="btn finish__close">
          Finalizar
        </button>
      </section>
    )
  }
}

export default Finish
