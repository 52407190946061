import React, { Component } from "react"
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom"
import Ponto from "./pages/Ponto/Ponto"
import Login from "./pages/Login/Login"

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      localStorage.getItem("id") ? (
        <Component {...props} />
      ) : (
        <Redirect to="/" />
      )
    }
  />
)

class Routing extends Component {
  render() {
    return (
      <Router>
        <section>
          <Route exact path="/" component={Login} />
          <PrivateRoute path="/ponto" component={Ponto} />
        </section>
      </Router>
    )
  }
}

export default Routing
