import ReactDom from "react-dom"

const show = ref => {
  const element = ReactDom.findDOMNode(ref.current)
  element.style.display = "block"
}

const hide = ref => {
  const element = ReactDom.findDOMNode(ref.current)
  element.style.display = "none"
}

export { show, hide }
