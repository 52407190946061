class Observable {
  constructor() {
    this.observers = []
    this.states = []
  }

  get currentState() {
    return this.states[this.states.length - 1]
  }

  subscribe(fn) {
    this.observers.push(fn)
  }

  unsubscribe(fn) {
    this.observers.filter(subscribe => subscribe !== fn)
  }

  notify(value) {
    this.observers.forEach(observer => observer(value))
  }

  set(key, value) {
    // Updating the state
    const copy = Object.assign({}, this.currentState)
    copy[key] = value

    // Pushing last modifications
    this.states.push(copy)

    // notify the state
    this.notify(this.currentState)
  }
}

export default Observable
