import React, { Component } from "react"
// import Location from '../Location/Location.js'
import QrForm from "../QrForm/QrForm.js"
import Admin from "../Admin/Admin"
import { showAdmin } from "../Admin/admin.refs"

class Header extends Component {
  constructor() {
    super()
    this.refAdmin = React.createRef()

    this.openModal = this.openModal.bind(this)
  }

  openModal() {
    showAdmin(this.refAdmin)
  }

  render() {
    return (
      <section className="sidebar__header">
        <img className="sidebar__logo" src="assets/logo.svg" alt="logo" />
        <div className="sidebar__adm">
          {localStorage.getItem("name")}
          <span onClick={this.openModal} className="sidebar__logout">
            <i className="material-icons sidebar__logout__icon"> settings </i>
          </span>
        </div>

        <Admin ref={this.refAdmin} />
      </section>
    )
  }
}

const Sidebar = () => {
  return (
    <aside className="sidebar">
      <Header />
      <QrForm />
      <div></div>
      {/* <Location /> */}
    </aside>
  )
}

export default Sidebar
