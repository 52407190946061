import React, { Component } from "react"
import { msgError, msgLoad, msgDefault } from "./admin.refs"
import { doLogout } from "../../services/auth"
import { hideAdmin } from "../Admin/admin.refs"

class Options extends Component {
  constructor() {
    super()
    this.refErrorMessage = React.createRef()
    this.refAdmin = React.createRef()

    this.state = {
      password: false,
    }

    this.passwordChange = this.passwordChange.bind(this)
    this.clickHandle = this.clickHandle.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.exit = this.exit.bind(this)
  }

  passwordChange(event) {
    msgDefault(this.refErrorMessage)
    const { value } = event.target

    this.setState({
      password: value,
    })
  }

  exit() {
    msgLoad(this.refErrorMessage)

    doLogout({
      email: localStorage.getItem("email"),
      senha: this.state.password,
    }).then(res => {
      const { permissao } = res.data

      if (permissao === 1 || permissao === 2) {
        localStorage.clear()
        document.location.href = "/"
      } else {
        msgError(this.refErrorMessage)
      }
    })
  }

  closeModal() {
    hideAdmin(this.refAdmin)
  }

  clickHandle(event) {
    event.stopPropagation()
  }

  render() {
    return (
      <section onClick={this.closeModal} ref={this.refAdmin} className="admin">
        <div onClick={this.clickHandle} className="admin__wrapper">
          <h1 className="admin__title"> Administração </h1>

          <div className="admin__divider">
            <p className="admin__divider__item">
              {" "}
              <b> Usuário: </b> {localStorage.getItem("name")}{" "}
            </p>
            <p className="admin__divider__item">
              {" "}
              <b> Email: </b> {localStorage.getItem("email")}{" "}
            </p>
          </div>

          <div className="admin__divider">
            <div className="form__divider">
              <label htmlFor="admin-password">
                Para sair do faceponto é necessário digitar a senha de
                administrador.
              </label>

              <input
                onChange={this.passwordChange}
                type="password"
                id="admin-password"
                className="admin__input-logout form-control"
                placeholder="Digite sua senha."
              />

              <div
                ref={this.refErrorMessage}
                className="admin__form-error form-message_error"
              >
                Senha inválida.
              </div>

              <button
                onClick={this.exit}
                disabled={!this.state.password}
                type="button"
                className="btn"
              >
                {" "}
                Sair{" "}
              </button>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Options
