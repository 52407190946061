import React, { Component } from "react"

export default class Offcam extends Component {
  render() {
    return (
      <div className="offcam">
        <section className="offcam__wrapper">
          <img
            className="offcam__image"
            src="assets/off-camera.svg"
            alt="off-camera"
          />
          <h1 className="offcam__title"> Câmera não encontrada! </h1>
          <p className="offcam__description">
            Para o correto funcionamento do faceponto é necessário que a câmera
            esteja ativada.
          </p>
        </section>
      </div>
    )
  }
}
