const inputSuccess = ref => {
  const input = ref.current
  input.classList.add("qrform__control--success")
  input.classList.remove("qrform__control--error")
  input.disabled = true
}

const inputDefault = ref => {
  const input = ref.current
  input.classList.remove("qrform__control--success")
  input.classList.remove("qrform__control--error")
  input.disabled = false
}

const inputError = ref => {
  const input = ref.current
  input.classList.add("qrform__control--error")
  input.classList.remove("qrform__control--success")
  input.disabled = false
}

const inputLoading = ref => {
  const input = ref.current
  input.disabled = true
}

const labelSuccess = (ref, name) => {
  const label = ref.current
  label.textContent = `✔ Matricula valida. (${name})`
}

const labelDefault = ref => {
  const label = ref.current
  label.textContent = "Caso não possua o QR-CODE digite a sua matricula."
}

const labelError = ref => {
  const label = ref.current
  label.textContent = "✘ Matricula inválida. Tente novamente."
}

const labelEmpty = ref => {
  const label = ref.current
  label.textContent = "Preencha o campo de matricula"
}

const labelLoading = ref => {
  const label = ref.current
  label.textContent = "Verificando..."
}

const submitOff = ref => {
  const submit = ref.current
  submit.style.display = "none"
}

const submitOn = ref => {
  const submit = ref.current
  submit.style.display = "inline-block"
}

export {
  inputSuccess,
  inputError,
  inputDefault,
  inputLoading,
  labelSuccess,
  labelDefault,
  labelError,
  labelEmpty,
  labelLoading,
  submitOff,
  submitOn,
}
