import ReactDom from "react-dom"

const showAdmin = ref => {
  const element = ReactDom.findDOMNode(ref.current)
  element.style.display = "flex"
}

const hideAdmin = ref => {
  const element = ReactDom.findDOMNode(ref.current)
  element.style.display = "none"
}

const msgError = ref => {
  const item = ref.current
  item.textContent = "Senha inválida"
  item.style.display = "block"
  item.style.color = "#FC5858"
}

const msgLoad = ref => {
  const item = ref.current
  item.textContent = "Verificando..."
  item.style.display = "block"
  item.style.color = "#333333"
}

const msgDefault = ref => {
  const item = ref.current
  item.style.display = "none"
}

export { msgLoad, msgError, msgDefault, showAdmin, hideAdmin }
