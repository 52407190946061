import PointState from "../../states/point-state.js"
import { flashSound } from "../../helpers/sound.js"

const snapShot = cam => {
  const video = cam

  const hiddenCanvas = document.querySelector("canvas")
  const context = hiddenCanvas.getContext("2d")

  const width = video.videoWidth
  const height = video.videoHeight

  if (width && height) {
    hiddenCanvas.width = width
    hiddenCanvas.height = height

    context.drawImage(video, 0, 0, width, height)

    PointState.set("photo", hiddenCanvas.toDataURL("image/jpeg", 0.5))

    flashSound()
  }
}

export default snapShot
