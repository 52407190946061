export const detectCam = (on, off) => {
  navigator.getMedia =
    navigator.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia ||
    navigator.msGetUserMedia

  navigator.getMedia({ video: true }, on, off)
}

export const stream = targetID => {
  const video = document.getElementById(targetID)

  navigator.mediaDevices
    .getUserMedia({ video: { facingMode: "environment" } })
    .then(function(stream) {
      // video.srcObject = stream
      // video.setAttribute("playsinline", true)
      video.play()
    })

  return video
}
