import axios from "axios"

const getLocation = new Promise((resolve, reject) => {
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
  }

  const showPosition = position => {
    resolve({
      LAT: position.coords.latitude,
      LON: position.coords.longitude,
    })
  }

  const error = () => {
    console.log("Geolocalização não é suportada nesse browser")

    resolve({
      LAT: null,
      LON: null,
    })
  }

  navigator.geolocation.getCurrentPosition(showPosition, error, options)
})

const getAddress = getLocation.then(data => {
  const ENDPOINT = [
    `https://nominatim.openstreetmap.org/reverse?format=json`,
    `&lat=${data.LAT}`,
    `&lon=${data.LON}`,
    `&zoom=18`,
    `&addressdetails=1`,
  ].join("")

  return axios.get(ENDPOINT).then(res => {
    return res.data
  })
})

const getFormatedAddress = getAddress.then(res => {
  const { address } = res
  const completeAddress = `
    ${address.city || ""} -
    ${address.state || ""} |
    ${address.road || ""} ─
    CEP: ${address.postcode || ""}
  `

  return completeAddress
})

export { getLocation, getAddress, getFormatedAddress }
