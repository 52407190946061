import React, { Component } from "react"
import { detectCam } from "../../services/camera.js"
import Offcam from "../Offcam/Offcam.js"

const PreviewCamFn = CamReference => {
  return class PreviewCam extends Component {
    constructor() {
      super()

      this.state = {
        hasCamera: false,
      }
    }

    checkCamera() {
      const cameraOn = () => {
        this.setState({ hasCamera: true })
      }

      const cameraOff = () => {
        this.setState({ hasCamera: false })
      }

      detectCam(cameraOn, cameraOff)
    }

    componentDidMount() {
      this.checkCamera()
    }

    render() {
      return (
        <React.Fragment>
          {this.state.hasCamera ? <CamReference /> : <Offcam />}
        </React.Fragment>
      )
    }
  }
}

export default PreviewCamFn
