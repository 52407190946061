import axios from "axios"
import SERVERS from "../helpers/servers.js"

const verifyRegister = ({ register, id }) => {
  const ENDPOINT = `${SERVERS.PRODUCTION}/ponto/validarcolaboradorbymatriculaandidempresa`

  return axios
    .post(ENDPOINT, {
      matricula: register,
      id: id,
    })
    .then(res => {
      return res.data
    })
}

const registerPoint = ({
  foto,
  idempresa,
  matricula,
  latitude,
  longitude,
  version,
}) => {
  const ENDPOINT = `${SERVERS.PRODUCTION}/ponto/cadastrarviaappbymatricula`
  const data = new FormData()

  data.append("foto", foto)
  data.set("idempresa", idempresa)
  data.set("matricula", matricula)
  data.set("latitude", null)
  data.set("longitude", null)
  data.set("version", version)

  return axios({
    method: "post",
    url: ENDPOINT,
    data: data,
    config: { headers: { "Content-Type": "multipart/form-data" } },
  })
}

export { verifyRegister, registerPoint }
