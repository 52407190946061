const registrationSound = () => {
  const audio = new Audio(`${process.env.PUBLIC_URL}/assets/sounds/qrcode.mp3`)
  audio.play()
}

const flashSound = () => {
  const audio = new Audio(`${process.env.PUBLIC_URL}/assets/sounds/flash.mp3`)
  audio.play()
}

const errorSound = () => {
  const audio = new Audio(`${process.env.PUBLIC_URL}/assets/sounds/error.mp3`)
  audio.play()
}

const successSound = () => {
  const audio = new Audio(`${process.env.PUBLIC_URL}/assets/sounds/confirm.wav`)
  audio.play()
}

const clearSound = () => {
  const audio = new Audio(`${process.env.PUBLIC_URL}/assets/sounds/clear.wav`)
  audio.play()
}

const voicePontoEntrada = () => {
  const audio = new Audio(
    `${process.env.PUBLIC_URL}/assets/sounds/voice_ponto_entrada.mp3`
  )
  audio.play()
}

const voicePontoSaida = () => {
  const audio = new Audio(
    `${process.env.PUBLIC_URL}/assets/sounds/voice_ponto_saida.mp3`
  )
  audio.play()
}

const voicePontoDuplicado = () => {
  const audio = new Audio(
    `${process.env.PUBLIC_URL}/assets/sounds/voice_ponto_duplicado.mp3`
  )
  audio.play()
}

export {
  registrationSound,
  flashSound,
  successSound,
  errorSound,
  clearSound,
  voicePontoEntrada,
  voicePontoSaida,
  voicePontoDuplicado,
}
