import React from "react"
import LoginForm from "../../components/LoginForm/LoginForm.js"

const Login = () => {
  return (
    <section className="login">
      <LoginForm />
    </section>
  )
}

export default Login
