import React, { Component } from "react"
import { stream } from "../../services/camera.js"
import PreviewCam from "../PreviewCam/PreviewCam.js"
import PointState from "../../states/point-state.js"
import { readQrCode } from "./qr-code.js"
import snapshot from "./snapshot.js"
import {
  hiddenButton,
  showButton,
  camLabelSuccess,
  camLabelDefault,
} from "./cam.refs.js"

class Cam extends Component {
  constructor() {
    super()
    this.refSnapShotButton = React.createRef()
    this.refCamLabel = React.createRef()

    this.state = {
      registration: undefined,
    }

    this.takeSnapShot = this.takeSnapShot.bind(this)
    this.keyPressHandle = this.keyPressHandle.bind(this)
  }

  successView(name) {
    showButton(this.refSnapShotButton)
    camLabelSuccess(this.refCamLabel, name)
  }

  errorView() {
    hiddenButton(this.refSnapShotButton)
    camLabelDefault(this.refCamLabel)
  }

  myCam() {
    return stream("cam")
  }

  takeSnapShot(event) {
    snapshot(this.myCam())
    window.removeEventListener("keypress", this.keyPressHandle)

    // Bug: Remove (button) focus when this action is called by the snapshot button.
    if (event) event.target.blur()
  }

  keyPressHandle(event) {
    if (event.code === "Space") {
      this.takeSnapShot()
      window.removeEventListener("keypress", this.keyPressHandle)
    }
  }

  getRegistration() {
    PointState.subscribe(data => {
      if (data.registration) {
        this.successView(data.name)
        window.addEventListener("keypress", this.keyPressHandle)
      } else {
        this.errorView()
      }
    })
  }

  componentDidMount() {
    readQrCode(this.myCam(), this.refCamLabel)
    this.getRegistration()
  }

  render() {
    return (
      <section className="cam">
        <p ref={this.refCamLabel} className="cam__label">
          Posicione o Qr-Code para a leitura da matricula.
        </p>

        <video id="cam" />
        <canvas hidden />

        <button
          ref={this.refSnapShotButton}
          onClick={this.takeSnapShot}
          type="button"
          className="cam__btn-photo"
        >
          <img src="assets/photo.svg" alt="icon" />
          Capturar imagem
        </button>
      </section>
    )
  }
}

export default PreviewCam(Cam)
