import Observable from "./Observables"

class Point extends Observable {
  constructor({ registration, photo, name }) {
    super()

    this.states.push({
      registration: registration,
      photo: photo,
      name: name,
    })
  }
}

const PointState = new Point({
  registration: undefined,
  photo: undefined,
  name: undefined,
})

export default PointState
