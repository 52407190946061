import { errorSound, successSound } from "./sound.js"

const getStatus = value => {
  switch (value) {
    case 0:
      successSound()
      return {
        error: false,
        message: "Ponto de entrada registrado.",
      }
    case 1:
      successSound()
      return {
        error: false,
        message: "Ponto de saída registrado.",
      }
    case 2:
      errorSound()
      return {
        error: true,
        message: "Gaminha falou que não existe.",
      }
    case 3:
      errorSound()
      return {
        error: true,
        message:
          "Ponto duplicado. Aguarde um período de 5 minutos e tente novamente.",
      }
    case 5:
      errorSound()
      return {
        error: true,
        message: "Ocorreu um error.",
      }
    case 6:
      errorSound()
      return {
        error: true,
        message: "Empresa desativada.",
      }
    case null:
      errorSound()
      return {
        error: true,
        message: "Colaborador suspenso ou de folga.",
      }
    default:
      errorSound()
      return {
        error: true,
        message: "Ocorreu um erro, comunique a administração",
      }
  }
}

export { getStatus }
