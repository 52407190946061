import React, { Component } from "react"
import { getLocation, getFormatedAddress } from "../../services/geo-location.js"
import { clearSound } from "../../helpers/sound.js"
import { registerPoint } from "../../services/register.js"
import { dataURItoBlob } from "../../helpers/images.js"
import { getStatus } from "../../helpers/status.js"
import PointState from "../../states/point-state.js"
import ConfirmLoad from "./ConfirmLoad/ConfirmLoad.js"
import Finish from "../../components/Finish/Finish.js"
import { show, hide } from "./confirm.refs.js"

class Confirm extends Component {
  constructor() {
    super()
    this.refContent = React.createRef()
    this.refLoad = React.createRef()
    this.refFinish = React.createRef()

    this.state = {
      location: "Indefinida",
      finished: false,
      status: {
        error: undefined,
        message: undefined,
      },
    }

    this.hitPoint = this.hitPoint.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.restart = this.restart.bind(this)
    this.cancel = this.cancel.bind(this)
  }

  handleClick(event) {
    event.stopPropagation()
  }

  cancel() {
    PointState.set("registration", null)
    PointState.set("photo", null)
    clearSound()
  }

  restart() {
    const { finished } = this.state

    if (finished) {
      this.setState({ finished: false })
      PointState.set("registration", null)
      PointState.set("photo", null)
    }
  }

  hitPoint() {
    show(this.refLoad)
    hide(this.refContent)

    getLocation
      .then(data => {
        const { LAT, LON } = data
        const { registration, photo } = this.props
        const idCompany = parseInt(localStorage.getItem("id"))
        const version = "1.2.0"

        registerPoint({
          foto: dataURItoBlob(photo),
          idempresa: idCompany,
          matricula: registration,
          latitude: LAT,
          longitude: LON,
          version: version,
        }).then(res => {
          const status = getStatus(res.data.tipo)
          hide(this.refLoad)
          show(this.refFinish)

          this.setState({
            finished: true,
            status: status,
          })
        })
      })
      .catch(console.log)
  }

  componentDidMount() {
    show(this.refContent)
    hide(this.refLoad)
    hide(this.refFinish)

    getFormatedAddress.then(address => {
      this.setState({
        location: address,
      })
    })
  }

  render() {
    return (
      <section className="confirm" onClick={this.restart}>
        <div onClick={this.handleClick} className="confirm__wrapper">
          {/* Main content */}
          <div className="confirm__content" ref={this.refContent}>
            <h1 className="confirm__name">{this.props.name}</h1>

            <span className="confirm__register">
              <b>Matricula: </b> {this.props.registration}
            </span>

            {/* <div className="confirm__location">
              <b> Localização: </b> {this.state.location}
            </div> */}

            <img
              className="confirm__photo"
              src={this.props.photo}
              alt="Foto do colaborador"
            />

            <button onClick={this.hitPoint} className="confirm__submit">
              <img src="assets/arrow.svg" alt="send" />
              Registrar ponto
            </button>

            <button
              onClick={this.cancel}
              className="confirm__restart btn btn-type--warn"
            >
              Cancelar
            </button>
          </div>

          {/* Loading */}
          <ConfirmLoad ref={this.refLoad} />

          {/* Finish  */}
          <Finish
            action={this.restart}
            status={this.state.status}
            ref={this.refFinish}
          />
        </div>

        <br />
        <br />
      </section>
    )
  }
}

export default Confirm
