import React, { Component } from "react"

class ConfirmLoad extends Component {
  render() {
    return (
      <div className="confirm-load">
        <div className="confirm-load__spinner">
          <div className="rect1" />
          <div className="rect2" />
          <div className="rect3" />
          <div className="rect4" />
          <div className="rect5" />
        </div>

        <p className="confirm-load__text"> Registrando ponto, aguarde. </p>
      </div>
    )
  }
}

export default ConfirmLoad
