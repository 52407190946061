import { verifyRegister } from "../../services/register.js"
import { registrationSound, errorSound } from "../../helpers/sound.js"
import PointState from "../../states/point-state.js"
import { camLabelError, camLabelSuccess, camLabelLoading } from "./cam.refs.js"

const readQrCode = (cam, refCamLabel) => {
  const scanner = new window.Instascan.Scanner({ video: cam })

  const scannerAction = content => {
    camLabelLoading(refCamLabel)

    verifyRegister({
      register: content,
      id: localStorage.getItem("id"),
    }).then(data => {
      if (data.validado === 1) {
        registrationSound()
        PointState.set("registration", content)
        PointState.set("name", data.nome)
        camLabelSuccess(refCamLabel, data.nome)
      } else {
        errorSound()
        PointState.set("registration", undefined)
        PointState.set("name", undefined)
        camLabelError(refCamLabel)
      }
    })
  }

  scanner.addListener("scan", scannerAction)

  window.Instascan.Camera.getCameras()
    .then(cameras => {
      if (cameras.length > 0) {
        scanner.start(cameras[0])
      } else {
        console.log("No cameras found.")
      }
    })
    .catch(e => {
      console.error(e)
    })
}

export { readQrCode }
