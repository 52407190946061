const hiddenButton = ref => {
  const button = ref.current
  button.style.display = "none"
}

const showButton = ref => {
  const button = ref.current
  button.style.display = "flex"
}

const camLabelSuccess = (ref, name) => {
  const label = ref.current
  label.classList.add("cam__label--success")
  label.classList.remove("cam__label--error")
  label.classList.remove("cam__label--loading")
  label.textContent = `✔ ${name} - capture uma imagem do seu rosto.`
}

const camLabelError = ref => {
  const label = ref.current
  label.classList.add("cam__label--error")
  label.classList.remove("cam__label--success")
  label.classList.remove("cam__label--loading")
  label.textContent = "Matricula inválida, tente novamente."
}

const camLabelDefault = ref => {
  const label = ref.current
  label.classList.remove("cam__label--error")
  label.classList.remove("cam__label--success")
  label.classList.remove("cam__label--loading")
  label.textContent = "Posicione o Qr-Code para a leitura da matricula."
}

const camLabelLoading = ref => {
  const label = ref.current
  label.classList.remove("cam__label--error")
  label.classList.remove("cam__label--success")
  label.classList.add("cam__label--loading")
  label.textContent = "Verificando a matricula ..."
}

export {
  hiddenButton,
  showButton,
  camLabelSuccess,
  camLabelError,
  camLabelDefault,
  camLabelLoading,
}
