import React, { Component } from "react"
import { _if } from "../../helpers/render.js"
import { doLogin } from "../../services/auth.js"
import { Redirect } from "react-router-dom"
import { isEmail } from "../../helpers/validator.js"
import Load from "../../components/Load/Load.js"

const ErrorMessage = props => (
  <span className="login-form__error"> {props.message} </span>
)

class LoginForm extends Component {
  constructor() {
    super()

    this.state = {
      email: undefined,
      password: undefined,
      invalidEmail: undefined,
      invalidPassword: undefined,
      authError: localStorage.getItem("is-logged") || undefined,
      load: undefined,
    }

    this.emailChange = this.emailChange.bind(this)
    this.passwordChange = this.passwordChange.bind(this)
    this.emailBlur = this.emailBlur.bind(this)
    this.passwordBlur = this.passwordBlur.bind(this)
    this.enter = this.enter.bind(this)
  }

  emailChange(event) {
    const { value } = event.target

    this.setState({
      email: value,
    })
  }

  emailBlur() {
    const { email } = this.state

    this.setState({
      invalidEmail: false,
    })

    if (!isEmail(email)) {
      this.setState({
        email: false,
        invalidEmail: true,
      })
    }
  }

  passwordChange(event) {
    this.setState({
      password: event.target.value,
    })
  }

  passwordBlur(event) {
    this.setState({
      invalidPassword: false,
    })

    if (!event.target.value) {
      this.setState({
        password: false,
        invalidPassword: true,
      })
    }
  }

  enter() {
    const { email, password } = this.state

    const success = () => {
      this.setState({
        authError: "allow",
        load: false,
      })
    }

    const error = () => {
      this.setState({
        authError: "denied",
        load: false,
      })
    }

    if (email && password) {
      this.setState({ load: true })

      doLogin({
        email: email,
        password: password,
        success: success,
        error: error,
      })
    }
  }

  pressingEnter = (event) => {
    if(event.key === "Enter"){
      this.enter()
    }
  }

  render() {
    if (this.state.authError === "allow") {
      return <Redirect to="/ponto" />
    }

    return (
      <div className="login-form">
        <img className="login-form__logo" src="assets/logo.svg" alt="logo" />
        <section className="login-form__wrapper">
          {_if(this.state.load, <Load />)}

          <h1 className="login-form__title"> Bem-vindo ao Faceponto! </h1>

          <div className="form-divider">
            <label htmlFor="login-email"> E-mail </label>
            <input
              onChange={this.emailChange}
              onBlur={this.emailBlur}
              onKeyPress={this.pressingEnter}
              className="form-control"
              type="text"
              id="login-email"
              name="login-email"
              placeholder="Digite seu email"
            />

            {_if(
              this.state.invalidEmail,
              <ErrorMessage message="Email inválido" />
            )}
          </div>

          <div className="form-divider">
            <label htmlFor="login-password"> Senha </label>
            <input
              onChange={this.passwordChange}
              onBlur={this.passwordBlur}
              onKeyPress={this.pressingEnter}
              className="form-control"
              type="password"
              id="login-senha"
              name="login-password"
              placeholder="Digite sua senha"
            />

            {_if(
              this.state.invalidPassword,
              <ErrorMessage message="Insira sua senha" />
            )}
          </div>

          <div className="form-divider">
            <a href="http://faceponto.com.br/login/login.html" className="">
              Recuperar senha
            </a>
          </div>

          <button
            type="button"
            disabled={!(!this.state.invalidEmail && this.state.password)}
            onClick={this.enter}
            className="login-form__submit btn"
          > Entrar
          </button>

          {_if(
            this.state.authError === "denied",
            <div className="login-form__error-box">
              O email ou senha que você inseriu está incorreto.
            </div>
          )}
        </section>
      </div>
    )
  }
}

export default LoginForm
